input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

html * {
  font-family: 'Maven Pro', sans-serif !important;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold;
  letter-spacing: -0.8px;
  color: #353535;
}

p {
  color: #737373;
  font-size: 17px;
}

hr {
  border: solid 1px #f2f2f2;
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 17px;
}

html, body {
  background: #fafafa;
  overflow-x: hidden;
  max-width: 100%;
}

.text-center {
  text-align: center;
}

input.input-field {
  background: #f2f2f2;
  border: 0px;
  -webkit-appearance: none;
}

.container-fluid {
  padding-left:0;
  padding-right:0;
}

.navbar {
  border-color: #fafafa;
  border-width: 0;
  margin-bottom: 0;
}

.navbar-default {
  background-color: #fafafa;
}

.navbar-toggle {
  border: 0px;
}

.navbar-nav li a {
  line-height: 50px;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  color: #737373;
  border-bottom: 1px solid #f2f2f2;
}

.navbar-brand {
  line-height: 86px;
  height: 86px;
  padding: 0 15px;
}

.navbar-brand img {
  display: inline-block;
  height: 36px;
}

.navbar-toggle {
  margin-top: 27px;
  margin-bottom: 0px;
}

#navbar-collapse-1 {
  border-color: #fcfcfc;
  border-width: 0;
}

.top-section {
  text-align: center;
}

.top-section #title {
  margin-top: 54px;
}

.top-section #subtitle {
  margin-top: 30px;
  margin-bottom: 45px;
  padding: 0 15px;
}

.top-section #app-store {
  height: 50px;
}

.top-section #android-user {
  text-decoration: none;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  color: #aaaaaa;
  margin-top: 21px;
  margin-bottom: 0px;
  cursor: pointer;
}

.dual-screen {
  margin-top: 25px;
  text-align: center;
}

.dual-screen .smaller {
  display: inline-block;
}

.dual-screen .bigger {
  display: none;
}

.dual-screen img {
  width: 96%;
}

.features {
  margin-top: 33px;
  padding-right: 30px;
  padding-left: 30px;
}

.feature-row .separator {
  border-radius: 2px;
  border: 0;
  height: 7px;
  margin: 0 auto 0 0;
  width: 120px;
}

.feature-row .separator.yellow {
  background: #1e89e5;
}

.feature-row .separator.blue {
  background: #1e89e5;
}

.feature-row .separator.red {
  background: #1e89e5;
}

.feature-row .description {
  text-align: left;
}

.feature-row .description h2 {
  margin-top: 33px;
  margin-bottom: 16px;
}

.feature-row .description p {
  margin-top: 20px;
  margin-bottom: 30px;
}

.feature-row .screen {
  text-align: center;
}

.feature-row img {
  width: 80%;
}

.feature-line {
  height: 1px;
  border: solid 1px #f2f2f2;
}

.press_screenshot .app_screenshot {
  text-align: center;
}

.press_screenshot img {
  width: 240px;
}

.comments {
  text-align: center
}

.comments .comment {
  margin-top: 66px;
  margin-bottom: 30px;
  position: relative;
}

.comment #left-confetti {
  position: absolute;
  height: 120%;
  left: -10%;
  top: -50%;
}

.comment #right-confetti {
  position: absolute;
  height: 120%;
  right: -10%;
  top: -50%;
}

.subscribe {
  text-align: center;
  margin-top: 40px;
}

input.email, input#subscribe {
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  height: 44px;
  width: 90%;
  max-width: 350px;
  margin-top: 20px;
}

input.button {
  background: #41d8dd;
}

.about-cards {
  text-align: center;
}

.about-card {
  display: inline-block;
  width: 250px;
  height: 250px;
  background: #fafafa;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  text-align: center;
  border-radius: 8px;
  margin-top: 40px;
  margin-right: 40px;
  margin-left: 40px;
}

.about-card .card-img {
  width: 90px;
  height: 90px;
  margin-top: 44px;
  margin-bottom: 25px;
}

.about-card p {
  font-size: 16px;
}

.about-card .card-link img {
  height: 11px;
}

footer {
  position: relative;
  z-index: 0;
}

footer .nav-link {
  text-decoration: none;
  font-size: 12px;
  line-height: 30px;
  color: #353535;
}

footer .nav-link img {
  height: 15px;
}

footer {
  padding: 0 0 45px;
}

footer .footer-nav {
  padding-top: 60px;
}

footer .nav-link {
  display: inline-block;
  margin: 0 10px;
}

footer #copyright {
  font-size: 12px;
  margin-top: 30px;
}

footer #skyline {
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;
}

footer #made-in {
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 11px;
}

/*  Small devices (mobiles, 404px and up) */
@media (min-width: 404px) {
  footer .nav-link {
    font-size: 15px;
  }
}

/*  Small devices (phablets, 544px and up) */
@media (min-width: 544px) {
  .dual-screen .bigger {
    display: inline-block;
  }
  .dual-screen .smaller {
    display: none;
  }
  .nav-link:not(:first-of-type) {
    margin-left: 15px;
  }

  .features {
    padding-right: 100px;
    padding-left: 100px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  p {
    font-size: 13px;
  }
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 20px;
  }

  .navbar-brand {
    line-height: 91px;
    height: 91px;
    padding: 0 15px;
  }

  .navbar-brand img {
    height: 31px;
  }

  .navbar-nav li a {
    line-height: 91px;
    height: 91px;
    border-bottom: 0px;
  }

  .top-section #title {
    margin-top: 30px;
  }

  .top-section #subtitle {
    margin-top: 21px;
  }

  .dual-screen {
    margin-top: 51px;
  }
  .dual-screen img {
    width: 70%;
  }

  .feature-row {
    display: flex;
    align-items: center;
  }

  .feature-row img {
    width: 100%;
  }

  .feature-row .separator {
    width: 80px;
  }

  .comments .comment {
    margin-top: 80px;
  }

  .subscribe {
    margin-top: 70px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  p {
    font-size: 14px;
  }
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 24px;
  }

  .features {
    padding-right: 150px;
    padding-left: 150px;
  }

  .feature-row:not(:first-of-type) {
    margin-top: 35px;
  }
}
/* 78 62 100 90 77 */
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  p {
    font-size: 16px;
  }
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 30px;
  }

  .navbar-brand {
    line-height: 130px;
    height: 130px;
    padding: 0 15px;
  }
  .navbar-brand img {
    height: 50px;
  }
  .navbar-nav li a {
    line-height: 139px;
    height: 130px;
    border-bottom: 0px;
  }
  .top-section #title {
    margin-top: 20px;
  }

  .features {
    padding-right: 175px;
    padding-left: 175px;
  }

  .feature-row:not(:first-of-type) {
    margin-top: 35px;
  }

  .feature-row img {
    width: 90%;
  }

  .feature-row .separator {
    width: 100px;
  }

  .comments .comment {
    margin-top: 150px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 690px;
  }

  .comments .commentor {
    margin-bottom: 87px;
  }

  .subscribe {
    margin-top: 100px;
  }
}

@media (max-width: 336px) {
  p {
    font-size: 13px;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 25px;
  }
  h3 {
    font-size: 14px;
  }

  .top-section #title {
    margin-top: 48px;
  }

  .top-section #subtitle {
    margin-top: 25px;
    margin-bottom: 40px;
    padding: 0 15px;
  }

  .top-section #app-store {
    height: 30px;
  }

  .dual-screen {
    margin-top: 10px;
  }

  .feature-row img {
    width: 100%;
  }
}

/* Extra small devices (portrait phones, less than 544px) */
@media (max-width: 543px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991px) {

}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199px) {
}